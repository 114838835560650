<!--
  集成商认证信息（普通服务用户）
 -->
<template>
  <div class="detail page_min_width">
    <detail-header title="集成商认证信息（普通服务用户）" />
    <div class="detail_main page_width" v-loading="loading">
      <div class="main_content">
        <info-header title="集成商认证信息" />
        <div style="padding: 20px 0;">
          <!-- 一般用户申请 -->
          <info-item
            :label-width="labelWidth"
            label="姓名"
            :value="formData.username">
          </info-item>
          <info-item
            :label-width="labelWidth"
            label="最高学历"
            :value="formData.highestEducation">
          </info-item>
          <info-item
            :label-width="labelWidth"
            label="毕业院校"
            :value="formData.university">
          </info-item>
          <info-item
            :label-width="labelWidth"
            label="学历证书编号"
            :value="formData.certificateNumber">
          </info-item>
          <info-item
            :label-width="labelWidth"
            label="社会职务"
            :value="formData.socialPosition">
          </info-item>
          <info-item
            :label-width="labelWidth"
            label="从事所选科技服务年限"
            :value="formData.workingYears">
          </info-item>
          <info-item
            :label-width="labelWidth"
            label="资格证书发证时间"
            :value="formData.issuingAt">
          </info-item>
          <info-item
            :label-width="labelWidth"
            label="资格证书图片或扫描件"
            value-type="image-list"
            image-fit="contain"
            :value="formData.certificateAttachment ? formData.certificateAttachment.split(',') : []">
          </info-item>
          <info-item
            :label-width="labelWidth"
            label="主持或参与的项目"
            :value="formData.projectCases">
          </info-item>
          <info-item
            :label-width="labelWidth"
            label="论文、研究（调研）报告及其他成果"
            :value="formData.achievementCases">
          </info-item>
          <info-item
            :label-width="labelWidth"
            label="获奖情况"
            :value="formData.awardCases">
          </info-item>
          <info-item
            :label-width="labelWidth"
            label="技术服务情况"
            :value="formData.situationDescription">
          </info-item>
          <info-item
            :label-width="labelWidth"
            label="相关凭证"
            value-type="file-list"
            :value="formData.relatedDocuments ? JSON.parse(formData.relatedDocuments) : []">
          </info-item>
          <info-item
            :label-width="labelWidth"
            label="服务对象代表"
            :value="formData.serviceObjectRepresentative">
          </info-item>
        </div>
        <!-- 被驳回 -->
        <template v-if="formData.certificationStatus === globalVar.CERTIFICATION_STATUS_REJECT">
          <info-header title="驳回信息" />
          <div style="padding: 20px 0;">
            <info-item
            :label-width="labelWidth"
            label="驳回原因"
            :value="formData.reason">
          </info-item>
          <!-- 已驳回状态下显示重新提交按钮 -->
          <el-row>
            <el-button
              type="primary"
              size="small"
              @click="orgCertification">
              重新申请
            </el-button>
          </el-row>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
// import * as api from '@/api'
import globalVar from '@/configs/globalVar'
export default {
  data () {
    return {
      loading: false,
      labelWidth: '180px',
      // formData: {},
      btnLoading: false
    }
  },
  computed: {
    formData () {
      return this.userInfo
    }
  },
  methods: {
    /**
     * 认证成为集成商
     *  + 如果是（法人/非法人）入驻的服务机构则跳转aggregator-admin
     *  + 如果是挂靠入驻的服务机构则跳转
     */
    orgCertification () {
      const { organizationRole, certificationStatus } = this.formData
      // 机构角色， 0 - 挂靠关系 / 1 - 法人关系
      if (organizationRole) {
        // certificationStatus: 0 - 未申请, 1 - 待处理, 2 - 已通过, 3 - 已驳回
        if (certificationStatus === globalVar.CERTIFICATION_STATUS_REJECT) {
          this.$router.push('/org-audit/aggregator-admin/apply')
        }
      } else {
        // 0 - 未申请, 1 - 待处理, 2 - 已通过, 3 - 已驳回
        if (certificationStatus === globalVar.CERTIFICATION_STATUS_REJECT) {
          this.$router.push('/org-audit/aggregator-person/apply')
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.detail {
  .detail_main {
    padding: 30px 0;
    .main_content {
      width: 560px;
      margin: 0 auto;
    }
  }
}
</style>
